/* custom-scrollbar.css */

::-webkit-scrollbar {
  height: 6px;
  width: 8px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 4px;
}

::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.brand-link {
  padding: 0 18px;
  display: block;
  img {
    max-width: 155px;
  }
}
