@font-face {
  font-family: 'Onest';
  font-weight: normal;
  font-style: normal;
  src: url('./Onest/TTF/Onest-Regular.ttf') format('truetype'),
    url('./Onest/WOFF/Onest-Regular.woff') format('woff');
}

@font-face {
  font-family: 'Onest';
  font-weight: 500;
  font-style: normal;
  src: url('./Onest/TTF/Onest-Medium.ttf') format('truetype'),
    url('./Onest/WOFF/Onest-Medium.woff') format('woff');
}

@font-face {
  font-family: 'Onest';
  font-weight: bold;
  font-style: normal;
  src: url('./Onest/TTF/Onest-Bold.ttf') format('truetype'),
    url('./Onest/WOFF/Onest-Bold.woff') format('woff');
}

@font-face {
  font-family: 'Onest';
  font-weight: 900;
  font-style: normal;
  src: url('./Onest/TTF/Onest-ExtraBold.ttf') format('truetype'),
    url('./Onest/WOFF/Onest-ExtraBold.woff') format('woff');
}

@font-face {
  font-family: 'Onest';
  font-weight: lighter;
  font-style: normal;
  src: url('./Onest/TTF/Onest-Light.ttf') format('truetype'),
    url('./Onest/WOFF/Onest-Light.woff') format('woff');
}
